<template>
  <a-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :destroyOnClose="true"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          label="父级节点"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <j-tree-select
            ref="treeSelect"
            placeholder="请选择父级节点"
            v-decorator="['pid', validatorRules.pid]"
            dict="sys_category,name,id"
            pidField="pid"
            pidValue="0"
            :disabled="pidDisabled"
          >
          </j-tree-select>
        </a-form-item>

        <a-form-item
          label="分类名称"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            v-decorator="['name', validatorRules.name]"
            placeholder="请输入分类名称"
          ></a-input>
        </a-form-item>

        <!-- <a-form-item
          label="类型编码"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            v-decorator="['code', validatorRules.code]"
            placeholder="请输入类型编码"
          ></a-input>
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol">
          <span style="font-size: 12px; color: red" slot="label"
            >编码规则(注)</span
          >
          <span style="font-size: 12px; color: red">
            编码值前缀需和父节点保持一致,比如父级节点编码是A01则当前编码必须以A01开头
          </span>
        </a-form-item> -->
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick';
import { httpAction, getAction } from '@/api/manage';
import JTreeSelect from '@/components/JTreeSelect';

export default {
  name: 'SysCategoryModal',
  components: {
    JTreeSelect,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      title: '操作',
      width: 800,
      pidDisabled: false,
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },

      confirmLoading: false,
      validatorRules: {
        code: {
          rules: [
            {
              required: true,
              message: '请输入类型编码!',
            },
            {
              validator: this.validateMyCode,
            },
          ],
        },
        pid: {},
        name: { rules: [{ required: true, message: '请输入类型名称!' }] },
      },
      url: {
        add: '/api/sys/category/add',
        edit: '/api/sys/category/edit',
        checkCode: '/api/sys/category/checkCode',
      },
      expandedRowKeys: [],
      pidField: 'pid',
      subExpandedKeys: [],
    };
  },
  created() {},
  methods: {
    add() {
      this.edit({},false);
    },
    edit(record,flag) {
      this.pidDisabled=flag
      this.form.resetFields();
      this.model = { ...record };
      this.visible = true;
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'pid', 'name', 'code'));
      });
    },
    close() {
      this.$emit('close');
      this.visible = false;
    },
    handleOk() {
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let httpurl = '';
          let method = '';
          if (!this.model.id) {
            httpurl += this.url.add;
            method = 'post';
          } else {
            httpurl += this.url.edit;
            method = 'put';
          }
          const formData = Object.assign(this.model, values);
          console.log('表单提交数据', formData);
          httpAction(httpurl, formData, method)
            .then((res) => {
              if (res !== undefined) {
                // that.$message.success(res.message);
                that.submitSuccess(formData);
              } else {
                that.$message.warning(res.message);
              }
            })
            .finally(() => {
              that.confirmLoading = false;
              that.close();
            });
        }
      });
    },
    handleCancel() {
      this.close();
    },
    popupCallback(row) {
      this.form.setFieldsValue(pick(row, 'pid', 'name', 'code'));
    },
    submitSuccess(formData) {
      if (!formData.id) {
        const treeData = this.$refs.treeSelect.getCurrTreeData();
        this.expandedRowKeys = [];
        this.getExpandKeysByPid(formData[this.pidField], treeData, treeData);
        if (formData.pid && this.expandedRowKeys.length == 0) {
          this.expandedRowKeys = this.subExpandedKeys;
        }
        this.$emit('ok', formData, this.expandedRowKeys.reverse());
      } else {
        this.$emit('ok', formData);
      }
    },
    getExpandKeysByPid(pid, arr, all) {
      if (pid && arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].key == pid) {
            this.expandedRowKeys.push(arr[i].key);
            this.getExpandKeysByPid(arr[i].parentId, all, all);
          } else {
            this.getExpandKeysByPid(pid, arr[i].children, all);
          }
        }
      }
    },
    validateMyCode(rule, value, callback) {
      const params = {};
      const vpid = this.form.getFieldValue('pid');
      if (vpid !== undefined) params.pid = vpid;
      else params.pid = '0';
      params.code = value;

      getAction(this.url.checkCode, params).then((res) => {
        if (res !== undefined) {
          callback();
        } else {
          callback(res);
        }
      });
    },
  },
};
</script>
